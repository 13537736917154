<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <!-- 진행상태 -->
          <c-select
            type="search"
            codeGroupCd="EDU_STATUS_CD"
            itemText="codeName"
            itemValue="code"
            name="documentStatusCd"
            label="진행상태"
            v-model="searchParam.documentStatusCd"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            type="search"
            codeGroupCd="EDU_KIND_FST_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdLarge"
            label="교육종류1"
            v-model="searchParam.educationKindCdLarge"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            type="search"
            codeGroupCd="EDU_KIND_SEC_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdSmall"
            label="교육종류2"
            v-model="searchParam.educationKindCdSmall"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <!-- 교육구분 -->
          <c-select
            type="search"
            codeGroupCd="EDU_CLASS_CD"
            itemText="codeName"
            itemValue="code"
            name="educationTypeCd"
            label="교육구분"
            v-model="searchParam.educationTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <!-- 교육기간 -->
          <c-datepicker
            :range="true"
            name="period"
            label="교육기간"
            v-model="period"
          ></c-datepicker>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="교육계획및결과 목록"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group v-if="isPlant" outline>
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="linkClick" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
        <q-btn-group v-else outline>
          <font style="font-size:0.8em;font-weight:300;" class="blinking" color="#C10015">
            ※ 공사현장관리 메뉴에서 공사현장을 추가하세요.
          </font>
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "education-result",
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "공사현장",
            style: 'width:180px',
            align: "center",
            fix: true,
            sortable: true,
          },
          // {
          //   name: "deptNm",
          //   field: "deptNm",
          //   label: "부서",
          //   align: "center",
          //   sortable: true,
          // },
          {
            name: "educationName",
            field: "educationName",
            label: "교육명",
            style: 'width:250px',
            align: "left",
            fix: true,
            sortable: true,
            type: "link",
          },
          {
            name: "educationCourseName",
            field: "educationCourseName",
            label: "교육과정",
            style: 'width:200px',
            fix: true,
            align: "center",
            sortable: true,
          },
          {
            name: "educationKindCdLargeName",
            field: "educationKindCdLargeName",
            label: "교육종류1",
            style: 'width:150px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationKindCdSmallName",
            field: "educationKindCdSmallName",
            label: "교육종류2",
            style: 'width:150px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationTypeName",
            field: "educationTypeName",
            label: "교육구분",
            align: "center",
            sortable: true,
          },
          {
            name: "educationDate",
            field: "educationDate",
            label: "교육기간",
            style: 'width:250px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationTime",
            field: "educationTime",
            label: "교육시간",
            style: 'width:150px',
            align: "center",
            sortable: true,
          },
          {
            name: "documentStatusName",
            field: "documentStatusName",
            label: "진행상태",
            style: 'width:90px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationMethodName",
            field: "educationMethodName",
            style: 'width:90px',
            label: "교육방법",
            align: "center",
            sortable: true,
          },
          {
            name: "evaluationEvalName",
            field: "evaluationEvalName",
            style: 'width:90px',
            label: "교육평가",
            align: "center",
            sortable: true,
          },
          {
            name: "educationOverview",
            field: "educationOverview",
            style: 'width:200px',
            label: "교육총평",
            align: "left",
            sortable: true,
          },
          // {
          //   name: "educationPurpose",
          //   field: "educationPurpose",
          //   style: 'width:200px',
          //   label: "학습목적",
          //   align: "left",
          //   sortable: true,
          // },
          {
            name: "relatedLawsName",
            field: "relatedLawsName",
            style: 'width:200px',
            label: "관련법규",
            align: "left",
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        documentStatusCd: null,
        startYmd: '',
        endYmd: '',
        educationKindCdSmall: null,
        educationKindCdLarge: null,
        educationTypeCd: null,
      },
      period: [ '', ''],
      editable: true,
      listUrl: "",
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
      deleteUrl: '',
      isPlant: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      if (!this.$store.getters.user.plantCds) {
        this.isPlant = false;
      }
      // url setting
      this.listUrl = selectConfig.sop.edu.result.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      if (this.searchParam.period && this.searchParam.period.length > 0) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd ='';
      }

      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.title = "교육결과 상세"; // 교육결과 상세
      this.popupOptions.param = {
        eduEducationId: row ? row.eduEducationId : '',
      };
      this.popupOptions.target = () =>
        import(`${"./educationResultDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  },
};
</script>
